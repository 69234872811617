var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            [
              _c("domain-workspace-toolbar", {
                attrs: { backLink: _vm.backLink, subtitle: "Snapshots" },
                scopedSlots: _vm._u([
                  {
                    key: "content",
                    fn: function() {
                      return [
                        _c("refresh-button", {
                          staticClass: "mr-2",
                          attrs: { refreshFunction: _vm.refreshItems }
                        })
                      ]
                    },
                    proxy: true
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-card",
        { staticClass: "mb-4" },
        [
          _c(
            "v-card-text",
            [
              _c("v-data-table", {
                attrs: {
                  headers: _vm.exportJobsTable.headers,
                  loading: _vm.isExportLoading,
                  items: _vm.exportJobsTable.data,
                  "items-per-page": _vm.exportJobsTable.itemsPerPage,
                  page: _vm.exportJobsTable.page,
                  "hide-default-footer": "",
                  dense: _vm.isSimpleTable,
                  "item-key": "uuid"
                },
                on: {
                  "update:page": function($event) {
                    return _vm.$set(_vm.exportJobsTable, "page", $event)
                  }
                },
                scopedSlots: _vm._u([
                  {
                    key: "item.label",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        [
                          _vm.ExportStateName.DONE,
                          _vm.ExportStateName.CANCELLED,
                          _vm.ExportStateName.ERRORED
                        ].indexOf(item.state) !== -1
                          ? _c(
                              "a",
                              {
                                attrs: { title: "View Snapshot" },
                                on: {
                                  click: function($event) {
                                    return _vm.goToSnapshot(item.uuid)
                                  }
                                }
                              },
                              [_vm._v(" " + _vm._s(item.label))]
                            )
                          : _c("span", [_vm._v(" " + _vm._s(item.label) + " ")])
                      ]
                    }
                  },
                  {
                    key: "item.state",
                    fn: function(ref) {
                      var item = ref.item
                      return [_c("job-status-icon", { attrs: { job: item } })]
                    }
                  },
                  {
                    key: "item.updatedAt",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _vm._v(
                          " " + _vm._s(_vm.formatDbDate(item.updatedAt)) + " "
                        )
                      ]
                    }
                  },
                  {
                    key: "item.objectSummary",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        item.state === _vm.ExportStateName.DONE ||
                        item.state === _vm.ExportStateName.CANCELLED ||
                        item.state === _vm.ExportStateName.ERRORED
                          ? _c("span", [
                              _vm._v(
                                " " + _vm._s(_vm.getObjectCount(item)) + " "
                              )
                            ])
                          : _c("span", [_vm._v("—")])
                      ]
                    }
                  },
                  {
                    key: "item.actions",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _vm.isModelExpired(item)
                          ? _c("div", [
                              _c(
                                "span",
                                {
                                  attrs: {
                                    title:
                                      "This snapshot is older than " +
                                      _vm.DOMO_EXPIRATION_TTL_DAYS +
                                      " so you cannot view it"
                                  }
                                },
                                [_c("b", [_vm._v("EXPIRED")])]
                              )
                            ])
                          : _c(
                              "div",
                              [
                                _c(
                                  "v-icon",
                                  {
                                    staticClass: "mr-4",
                                    attrs: { title: "Edit" },
                                    on: {
                                      click: function($event) {
                                        return _vm.showEditDialog(item.uuid)
                                      }
                                    }
                                  },
                                  [_vm._v(" mdi-pencil")]
                                ),
                                _c(
                                  "v-icon",
                                  {
                                    staticClass: "mr-4",
                                    attrs: {
                                      title: "Download object report",
                                      color: "primary",
                                      disabled: _vm.isExportLoading
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.runDownloadJobReport(
                                          item.uuid
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v("mdi-file-excel")]
                                ),
                                _c(
                                  "v-icon",
                                  {
                                    attrs: {
                                      title: "Browse objects",
                                      color: "accent-4",
                                      disabled:
                                        item.state ===
                                        _vm.ExportStateName.IN_PROGRESS
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.goToDomainObjectBrowserPage(
                                          item.uuid
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v(" mdi-magnify")]
                                )
                              ],
                              1
                            )
                      ]
                    }
                  }
                ])
              }),
              _c(
                "div",
                { staticClass: "text-center pt-2" },
                [
                  _c("v-pagination", {
                    attrs: {
                      length: _vm.exportJobsTable.pageCount,
                      disabled: _vm.isExportLoading
                    },
                    on: {
                      input: function($event) {
                        return _vm.getNextPage()
                      }
                    },
                    model: {
                      value: _vm.exportJobsTable.page,
                      callback: function($$v) {
                        _vm.$set(_vm.exportJobsTable, "page", $$v)
                      },
                      expression: "exportJobsTable.page"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("confirm"),
      _vm.isEditDialogOpen
        ? _c(
            "v-dialog",
            {
              attrs: {
                "retain-focus": false,
                persistent: "",
                width: "45vw",
                scrollable: "",
                lass: "no-x"
              },
              model: {
                value: _vm.isEditDialogOpen,
                callback: function($$v) {
                  _vm.isEditDialogOpen = $$v
                },
                expression: "isEditDialogOpen"
              }
            },
            [
              _c(
                "v-card",
                { attrs: { loading: _vm.isLoading } },
                [
                  _c("v-card-title", [_vm._v(" Edit Snapshot ")]),
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-list-item",
                        { staticClass: "px-0", attrs: { "two-line": "" } },
                        [
                          _c(
                            "v-list-item-content",
                            [
                              _c("v-list-item-title", [_vm._v("Domain")]),
                              _c("v-list-item-subtitle", [
                                _vm._v(_vm._s(_vm.selectedDomain.domainName))
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            [
                              _c(
                                "v-form",
                                { attrs: { disabled: _vm.isLoading } },
                                [
                                  _c(
                                    "div",
                                    { attrs: { hidden: "" } },
                                    [_c("v-text-field")],
                                    1
                                  ),
                                  _c("v-text-field", {
                                    staticClass: "mt-6",
                                    attrs: {
                                      outlined: "",
                                      label: "Snapshot Name"
                                    },
                                    model: {
                                      value: _vm.formData.label,
                                      callback: function($$v) {
                                        _vm.$set(_vm.formData, "label", $$v)
                                      },
                                      expression: "formData.label"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            color: "error",
                            disabled: _vm.isLoading,
                            text: ""
                          },
                          on: {
                            click: function($event) {
                              return _vm.closeEditDialog()
                            }
                          }
                        },
                        [_vm._v(" Cancel ")]
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "primary", disabled: _vm.isLoading },
                          on: { click: _vm.editExport }
                        },
                        [_vm._v(" Save ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }