















































































































































import Confirm from '@/components/confirmation/Confirmation.vue';
import JobStatusIcon from '@/components/job-status-icon/JobStatusIcon.vue';
import DomainWorkspaceToolbar from '@/components/navigation/DomainWorkspaceToolbar.vue';
import RefreshButton from '@/components/navigation/RefreshButton.vue';
import { MAX_ITEMS_PER_PAGE, RunQueryOptions } from '@/module/api/common';
import { useDomainApi } from '@/module/api/domain';
import { DOMO_EXPIRATION_TTL_DAYS, isModelExpired } from '@/module/api/domo';
import { ExportModel, ExportStateName, useExportApi } from '@/module/api/export';
import router from '@/router';
import { formatDbDate, toNum, usePagination } from '@/utils';
import { computed, defineComponent, reactive, toRefs } from '@vue/composition-api';
import Vue from 'vue';

// TODO: deprecate cachePage and the page cache logic, its not working

const { selectedItem: selectedDomain, refreshItem: refreshDomain } = useDomainApi();

const {
  getItems: getExportJobs,
  refreshItem: refreshExport,
  items: exportJobs,
  isLoading: isExportLoading,
  runDownloadJobReport,
  selectedItem: selectedExport,
  updateItem: updateExport,
} = useExportApi();

const { areThereMorePages, getNextPageQueryParams, emptyPageableTable, applyPageChange } = usePagination();

const emptyFormData = () => {
  return {
    label: '',
  };
};

const state = reactive({
  domainUuid: '',
  isDownloadingReport: false,
  isSimpleTable: false,
  isEditDialogOpen: false,
  formData: emptyFormData() as Partial<ExportModel>,
});

const exportJobsTable = reactive({
  ...emptyPageableTable(),
  headers: [
    { text: 'State', value: 'state', sortable: false, width: 50 },
    { text: 'Label', value: 'label', sortable: false },
    { text: 'Count', value: 'objectSummary', sortable: false },
    { text: 'Date', value: 'updatedAt', sortable: false },
    { text: 'Actions', value: 'actions', sortable: false, width: 200 },
  ],
  itemsPerPage: MAX_ITEMS_PER_PAGE,
});

const queryExportJobs = async (runQueryOptions: RunQueryOptions) => {
  const query = runQueryOptions.nextPage
    ? getNextPageQueryParams({
        page: exportJobsTable.page,
        limit: MAX_ITEMS_PER_PAGE,
      })
    : { page: exportJobsTable.page, limit: MAX_ITEMS_PER_PAGE };
  query['filter[domainUuid]'] = selectedDomain.value?.uuid;
  const getExportJobsParams = {
    query,
    raw: true,
  };
  const getExportJobsRes = (await getExportJobs(getExportJobsParams)) as any;
  exportJobsTable.data = getExportJobsRes._embedded;
  // TODO: getExportJobsRes is the raw resposne so we use any.
  const areMorePages = areThereMorePages(getExportJobsRes as any);
  if (areMorePages && exportJobsTable.pageCount <= exportJobsTable.page) {
    exportJobsTable.pageCount = exportJobsTable.pageCount + 1;
  }
  return exportJobsTable.data;
};

const getNextPage = async () => {
  queryExportJobs({ nextPage: true });
};

const editExport = async () => {
  selectedExport.value.label = state.formData.label ?? '';
  await updateExport(selectedExport.value);
  state.isEditDialogOpen = false;
};

const initializeTableData = async () => {
  await queryExportJobs({});
};

const setFormData = async (): Promise<void> => {
  state.formData.label = selectedExport.value.label;
};

const initializeData = async (forceRefresh = false) => {
  await refreshDomain(state.domainUuid, forceRefresh);
  await setFormData();
  await initializeTableData();
};

// Pass
const goToDomainObjectBrowserPage = (extractJobUuid: string) => {
  router.push({
    name: 'DomainObjectBrowser',
    params: {
      uuid: selectedDomain.value?.uuid,
      jobUuid: extractJobUuid,
    },
  });
};

const getObjectCount = (item: ExportModel): string => {
  const total = toNum(item?.objectSummary?.total);
  return total.toLocaleString('en-us');
};

const goToSnapshot = (jobUuid: string) => {
  router.push({
    name: 'WorkspaceExportJobDetail',
    params: {
      uuid: selectedDomain?.value?.uuid,
      jobUuid: jobUuid,
      overrideBackLink: `/domain/${selectedDomain.uuid}/workspace/export-jobs`,
    },
  });
};

const refreshItems = () => {
  initializeData(true);
};

const reset = async () => {
  return;
};

export default defineComponent({
  name: 'WorkspaceExportJobList',
  components: { RefreshButton, Confirm, DomainWorkspaceToolbar, JobStatusIcon },
  props: {
    uuid: {
      type: String,
    },
    simpleTable: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    state.domainUuid = props.uuid || '';
    state.isSimpleTable = props.simpleTable;
    initializeData();
    return {
      ...toRefs(state),
      reset,
      formatDbDate,
      refreshItems,
      selectedDomain,
      exportJobsTable,
      exportJobs,
      isExportLoading,
      applyPageChange,
      queryExportJobs,
      ExportStateName,
      goToDomainObjectBrowserPage,
      goToSnapshot,
      getObjectCount,
      runDownloadJobReport,
      getNextPage,
      editExport,
      DOMO_EXPIRATION_TTL_DAYS,
      isModelExpired,
      isLoading: computed(() => {
        return isExportLoading.value;
      }),
      closeEditDialog: (): void => {
        state.isEditDialogOpen = false;
      },
      showEditDialog: async (uuid: string): Promise<void> => {
        Vue.$log.debug(`WorkspaceExportJobList: Opening edit form for ${uuid}`);
        state.isEditDialogOpen = true;
        await refreshExport(uuid);
        await setFormData();
        Vue.$log.debug(`WorkspaceExportJobList: form data: `, state.formData);
      },
      backLink: `/domain/${props.uuid}/workspace`,
    };
  },
});
